.Banner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 30rem;
  background-color: #000;

  &-Image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    opacity: 0.4;
  }

  &-Content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--white);
    font-weight: bold;

    &-ProfileImage {
      height: 12rem;
      width: 12rem;
      object-fit: cover;
      border-radius: 7rem;
      border: 5px solid var(--white);
      transition: 200ms;

      &:hover {
        position: relative;
        height: 14rem;
        width: 14rem;
        margin: -1rem auto;
        z-index: 1;
        transition: 200ms;
        cursor: pointer;
      }
    }

    &-Name {
      font-size: 2rem;
      margin-top: 2rem;
    }

    &-Description {
      font-size: 1.3rem;
      margin-top: 1rem;
    }
  }

  &-Social {
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: -1.5rem;
    z-index: 1;
  }
}

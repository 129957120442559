.ProgressBar {
  &-Image {
    height: 1.5rem;
    margin-right: 0.5rem;
  }

  &-Title {
    display: flex;
    text-align: start;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  &-Bar {
    background-color: var(--grey);
    border-radius: 0.3rem;
  }

  &-Progress {
    height: inherit;
    background-color: var(--theme);
    border-radius: inherit;
  }

  &-Level {
    color: var(--white);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: 0.8rem;
  }
}

:root {
  --black: #333;
  --white: #fff;
  --light-grey: #eee;
  --grey: #dadada;
  --dark-grey: #aaa;
  --theme: #035397;
}

/* Screen width break points */
$breakpoint-one: 1200px;
$breakpoint-two: 920px;

.Tile {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 2rem;
}

.TileTitle {
  text-align: start;
  font-size: 2rem;
  margin-bottom: 2rem;
}

a {
  text-decoration: none;
  color: var(--theme);

  &:hover {
    cursor: pointer;
    color: var(--black);
  }
}

.Section {
  width: 70rem;
  margin: 5rem auto;
}

@media screen and (max-width: $breakpoint-one) {
  .Section {
    width: 50rem;
  }
}

@media screen and (max-width: $breakpoint-two) {
  .Section {
    width: 20rem;
  }
}

.Footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--light-grey);
  padding: 3rem 0;

  &-Icons {
    display: flex;
  }

  &-Name {
    font-size: 1.8rem;
    font-weight: 700;
    margin-top: 3rem;
  }

  &-Info {
    margin-top: 1rem;
    color: var(--black);

    & a {
      text-decoration: none;
      color: inherit;

      &:hover {
        color: var(--theme);
      }
    }
  }
}

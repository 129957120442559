@import '../../scss/settings.scss';

.Projects {
  margin-bottom: 5rem;

  &-List {
    display: grid;
    grid-template-columns: 1fr;
    gap: 3rem;
    margin: auto;
    width: 70rem;
  }
}

@media screen and (max-width: $breakpoint-one) {
  .Projects {
    &-List {
      display: grid;
      grid-template-columns: 1fr;
      gap: 3rem;
      width: 50rem;
    }
  }
}
@media screen and (max-width: $breakpoint-two) {
  .Projects {
    &-List {
      width: 20rem;
    }
  }
}
